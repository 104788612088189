<template>
  <div id="app">
    <div>
      <form enctype="multipart/form-data" novalidate>
        <div class="dropbox">
          <v-row no-gutters>
            <input
              type="file"
              id="file-upload"
              multiple
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="filesChange($event.target.name, $event.target.files)"
              accept="image/*"
              class="input-file"
            />

            <img src="@/assets/images/upload.svg" class="px-4 mt-2" />

            <v-col
              :cols="$vuetify.breakpoint.smAndUp ? '6' : '9'"
              class="mt-2"
              :class="$vuetify.breakpoint.smAndUp ? 'ml-4' : ''"
            >
              <span
                class="text-subtitle-2 font-weight-medium grey--text text--darken-4"
              >
                {{ $t("dragAndDropText") }}<br />
                <span
                  style="font-size: 10px"
                  class="font-weight-regular grey--text text--darken-2"
                  >{{ $t("supportMaxSizeText") }}</span
                >
              </span>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndUp ? '3' : '8'"
              :class="$vuetify.breakpoint.smAndUp ? 'mt-2 ml-2' : 'mb-2 ml-16'"
            >
              <label for="file-upload" class="mt-2 custom-file-upload">{{
                $t("chooseFiles")
              }}</label>
            </v-col>
          </v-row>
        </div>
      </form>

      <div>
        <v-alert
          v-if="isError"
          type="error"
          icon="mdi-alert-outline"
          text
          class="caption font-weight-regular my-3 py-3 px-5"
          dismissible
        >
          <span
            class="font-weight-bold grey--text text--darken-4"
            v-if="unsupportedFile"
            >{{ unsupportedFile }}</span
          >
          <div>{{ fileErrorMsg }}</div>
        </v-alert>
        <v-card flat class="my-4">
          <div class="mt-3" v-for="(file, i) of fileInfo" :key="i">
            <v-chip
              class="my-2"
              color="blue darken-2"
              text-color="white"
              label
              variant="outlined"
              small
            >
              <v-icon small left> mdi-paperclip </v-icon>
              {{ file.name }}
            </v-chip>
            <span
              style="font-size: 10px"
              class="ml-4 font-weight-regular grey--text text--darken-2"
            >
              {{ formatFileSize(file.size) }}
            </span>
            <v-btn
              class="my-auto"
              aria-label="cross"
              icon
              @click="removeFile(file)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "DigitalOcean",
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      fileInfo: [],
      isError: false,
      maxTotalFileSize: 40 * 1024 * 1024,
      unsupportedFileTypes: ["mp4", "mp3", "avi", "mov", "exe", "msi", "bat"],
      fileErrorMsg: "",
      unsupportedFile: null,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
  },
  methods: {
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fieldName, fileList) {
      this.isError = false;
      this.fileErrorMsg = "";
      this.unsupportedFile = null;
      for (const file of fileList) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (this.unsupportedFileTypes.includes(fileExtension)) {
          this.isError = true;
          this.fileErrorMsg = "Sorry, this file type not supported.";
          this.unsupportedFile = file.name;
          return;
        }
        let fileTotal = file.size;
        let fileSize =
          fileTotal < 1024 * 1024
            ? (fileTotal / 1024).toFixed(2) + " KB"
            : (fileTotal / (1024 * 1024)).toFixed(2) + " MB";

        //const ITEM = { name: file.name, size: fileSize, uploadPercentage: 90 };
        this.fileInfo.push(file);

        const totalSize = Array.from(this.fileInfo).reduce(
          (total, file) => total + file.size,
          0,
        );
        const combinedSize =
          totalSize + this.calculateTotalFileSize(this.fileInfo);

        // Check if the combined total size exceeds the limit
        if (combinedSize > this.maxTotalFileSize) {
          this.isError = true;
          return;
        }
      }
    },
    removeFile(file) {
      this.fileInfo = this.fileInfo.filter((f) => f !== file);
    },
    calculateTotalFileSize(files) {
      return files.reduce((total, file) => total + file.size, 0);
    },
    formatFileSize(fileSize) {
      return fileSize < 1024 * 1024
        ? (fileSize / 1024).toFixed(2) + " KB"
        : (fileSize / (1024 * 1024)).toFixed(2) + " MB";
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 1px dashed grey;
  background: #f5f5f5;
  color: #323232;
  min-height: 75px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 75px;
  position: absolute;
  cursor: pointer;
}
.custom-file-upload {
  border: 1px solid #1976d2;
  border-radius: 8px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  color: #1976d2;
}
</style>
