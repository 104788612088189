import { render, staticRenderFns } from "./FileAttachments.vue?vue&type=template&id=3ef4083e&"
import script from "./FileAttachments.vue?vue&type=script&lang=js&"
export * from "./FileAttachments.vue?vue&type=script&lang=js&"
import style0 from "./FileAttachments.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VChip,VCol,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ef4083e')) {
      api.createRecord('3ef4083e', component.options)
    } else {
      api.reload('3ef4083e', component.options)
    }
    module.hot.accept("./FileAttachments.vue?vue&type=template&id=3ef4083e&", function () {
      api.rerender('3ef4083e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/project/FileAttachments.vue"
export default component.exports