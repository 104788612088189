var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", [
      _c(
        "form",
        { attrs: { enctype: "multipart/form-data", novalidate: "" } },
        [
          _c(
            "div",
            { staticClass: "dropbox" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("input", {
                    staticClass: "input-file",
                    attrs: {
                      type: "file",
                      id: "file-upload",
                      multiple: "",
                      name: _vm.uploadFieldName,
                      disabled: _vm.isSaving,
                      accept: "image/*",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.filesChange(
                          $event.target.name,
                          $event.target.files
                        )
                      },
                    },
                  }),
                  _c("img", {
                    staticClass: "px-4 mt-2",
                    attrs: { src: require("@/assets/images/upload.svg") },
                  }),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2",
                      class: _vm.$vuetify.breakpoint.smAndUp ? "ml-4" : "",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.smAndUp ? "6" : "9",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-subtitle-2 font-weight-medium grey--text text--darken-4",
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("dragAndDropText"))),
                          _c("br"),
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-regular grey--text text--darken-2",
                              staticStyle: { "font-size": "10px" },
                            },
                            [_vm._v(_vm._s(_vm.$t("supportMaxSizeText")))]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndUp
                        ? "mt-2 ml-2"
                        : "mb-2 ml-16",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.smAndUp ? "3" : "8",
                      },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mt-2 custom-file-upload",
                          attrs: { for: "file-upload" },
                        },
                        [_vm._v(_vm._s(_vm.$t("chooseFiles")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        [
          _vm.isError
            ? _c(
                "v-alert",
                {
                  staticClass: "caption font-weight-regular my-3 py-3 px-5",
                  attrs: {
                    type: "error",
                    icon: "mdi-alert-outline",
                    text: "",
                    dismissible: "",
                  },
                },
                [
                  _vm.unsupportedFile
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold grey--text text--darken-4",
                        },
                        [_vm._v(_vm._s(_vm.unsupportedFile))]
                      )
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(_vm.fileErrorMsg))]),
                ]
              )
            : _vm._e(),
          _c(
            "v-card",
            { staticClass: "my-4", attrs: { flat: "" } },
            _vm._l(_vm.fileInfo, function (file, i) {
              return _c(
                "div",
                { key: i, staticClass: "mt-3" },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "my-2",
                      attrs: {
                        color: "blue darken-2",
                        "text-color": "white",
                        label: "",
                        variant: "outlined",
                        small: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v(" mdi-paperclip "),
                      ]),
                      _vm._v(" " + _vm._s(file.name) + " "),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "ml-4 font-weight-regular grey--text text--darken-2",
                      staticStyle: { "font-size": "10px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.formatFileSize(file.size)) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeFile(file)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }